import React from 'react';

const CareersCompanyEquipment = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.62548 43C2.45959 43 2.30055 42.9281 2.18325 42.8002C2.06595 42.6723 2 42.4988 2 42.3179V25.9478C2.00155 25.8595 2.02048 25.7723 2.05497 25.6923C2.08947 25.6124 2.13893 25.5414 2.20035 25.4838L7.92984 19.2085C7.98819 19.1425 8.0577 19.0902 8.13508 19.0543C8.21246 19.0184 8.29594 19 8.38002 19C8.46409 19 8.54758 19.0184 8.62496 19.0543C8.70233 19.0902 8.77184 19.1425 8.83019 19.2085C8.93919 19.3391 9 19.5095 9 19.6861C9 19.8627 8.93919 20.0331 8.83019 20.1637L3.25096 26.2479V42.2633C3.25788 42.3573 3.24691 42.4519 3.21859 42.5411C3.19027 42.6302 3.14544 42.7119 3.08665 42.7812C3.02786 42.8504 2.95648 42.9057 2.87714 42.9434C2.79779 42.981 2.712 43.0003 2.62548 43Z"
      fill="#475467"
    />
    <path
      d="M11.247 37.9707C11.1135 37.9689 10.9832 37.9297 10.8722 37.8574C10.7612 37.7851 10.674 37.6828 10.6214 37.5632C10.5688 37.4437 10.5534 37.3119 10.5762 37.1837C10.599 37.0556 10.6593 36.9367 10.7501 36.8413C11.1239 36.5252 11.427 36.1376 11.6408 35.7028C11.8545 35.268 11.9742 34.7949 11.9927 34.3131C11.8827 33.4779 11.46 32.7116 10.8054 32.1615C10.7253 32.1017 10.6606 32.0242 10.6173 31.9353C10.5741 31.8464 10.5534 31.7489 10.5567 31.6507C10.5467 31.5568 10.5589 31.4618 10.5924 31.3732C10.6259 31.2847 10.68 31.2047 10.7501 31.1395L14.5055 27.482C15.1858 26.8616 15.6054 26.0173 15.6819 25.1127C15.7585 24.208 15.487 23.3079 14.9201 22.5874L7.10474 30.1983C6.97179 30.2954 6.80733 30.3427 6.64156 30.3312C6.47579 30.3198 6.32017 30.2503 6.20265 30.1359C6.08514 30.0215 6.01352 29.8696 6.00174 29.7081C5.98996 29.5467 6.03822 29.3865 6.13793 29.2571L14.4225 21.1889C14.4926 21.1249 14.5758 21.0755 14.6659 21.0431C14.756 21.0108 14.8518 20.9964 14.9477 21.0008C15.0371 21.0024 15.125 21.0225 15.2059 21.0595C15.2868 21.0966 15.3587 21.1498 15.417 21.2158C15.918 21.6918 16.3162 22.2606 16.5881 22.8888C16.8599 23.5169 17 24.1917 17 24.8733C17 25.555 16.8599 26.2297 16.5881 26.8579C16.3162 27.486 15.918 28.0549 15.417 28.5309L12.1309 31.7314C12.8052 32.4826 13.2119 33.426 13.2905 34.4208C13.2909 35.0935 13.1435 35.7582 12.8577 36.3705C12.5719 36.9829 12.1547 37.5286 11.634 37.9707C11.5083 38.0098 11.3728 38.0098 11.247 37.9707Z"
      fill="#475467"
    />
    <path
      d="M16.7023 43C16.516 43 16.3375 42.928 16.2058 42.8001C16.074 42.6721 16 42.4986 16 42.3176V39.3695C15.9994 39.1976 16.0654 39.0319 16.1852 38.9054C16.305 38.7789 16.4697 38.7009 16.6461 38.6872C17.4046 38.688 18.1344 38.4048 18.6837 37.8965C19.233 37.3882 19.559 36.6939 19.5952 35.9576V32.6824C19.5952 32.5014 19.6692 32.3279 19.8009 32.1999C19.9326 32.0719 20.1112 32 20.2975 32C20.4837 32 20.663 32.0719 20.7947 32.1999C20.9264 32.3279 20.9998 32.5014 20.9998 32.6824V35.9576C21.0108 36.9471 20.6526 37.9068 19.9916 38.6595C19.3305 39.4122 18.412 39.9068 17.4046 40.0519V42.3992C17.3839 42.5657 17.3003 42.719 17.1707 42.8297C17.0412 42.9405 16.8748 43.0012 16.7023 43Z"
      fill="#475467"
    />
    <path
      d="M18.3214 47C18.1415 47 17.9689 46.9354 17.8417 46.8205C17.7144 46.7056 17.6429 46.5497 17.6429 46.3872V43.2255H1.35714V46.3872C1.35714 46.5497 1.2856 46.7056 1.15834 46.8205C1.03109 46.9354 0.85854 47 0.678571 47C0.498603 47 0.326057 46.9354 0.1988 46.8205C0.0715434 46.7056 0 46.5497 0 46.3872V42.6128C0 42.4503 0.0715434 42.2941 0.1988 42.1792C0.326057 42.0643 0.498603 42 0.678571 42H18.3214C18.5014 42 18.6739 42.0643 18.8012 42.1792C18.9285 42.2941 19 42.4503 19 42.6128V46.3872C19 46.5497 18.9285 46.7056 18.8012 46.8205C18.6739 46.9354 18.5014 47 18.3214 47Z"
      fill="#475467"
    />
    <path
      d="M24.6907 33H15.4022C15.2282 33 15.0614 32.9294 14.9384 32.8032C14.8154 32.677 14.7462 32.5056 14.7462 32.3272C14.7462 32.1487 14.8154 31.9776 14.9384 31.8514C15.0614 31.7253 15.2282 31.6543 15.4022 31.6543H24.6907C24.9575 31.6473 25.211 31.5337 25.3973 31.3376C25.5835 31.1415 25.6882 30.8788 25.6881 30.605V3.39502C25.6916 3.25847 25.6681 3.12273 25.6195 2.99552C25.571 2.86832 25.4979 2.75234 25.4049 2.6545C25.312 2.55667 25.201 2.47891 25.0782 2.42584C24.9555 2.37277 24.8239 2.34563 24.6907 2.34568H11.3095C11.1763 2.34563 11.044 2.37277 10.9213 2.42584C10.7986 2.47891 10.6875 2.55667 10.5946 2.6545C10.5016 2.75234 10.4291 2.86832 10.3806 2.99552C10.3321 3.12273 10.3086 3.25847 10.3121 3.39502V24.9259C10.3121 25.1044 10.2429 25.2758 10.1199 25.402C9.99688 25.5281 9.83008 25.5988 9.65611 25.5988C9.48214 25.5988 9.31534 25.5281 9.19232 25.402C9.06931 25.2758 9.00015 25.1044 9.00015 24.9259V3.39502C8.99668 3.08174 9.05414 2.77117 9.16862 2.48071C9.2831 2.19025 9.45224 1.92585 9.667 1.70307C9.88175 1.48028 10.1378 1.30337 10.4197 1.18267C10.7016 1.06196 11.004 0.99998 11.3095 1H24.6907C25.3055 1.00709 25.8925 1.26251 26.3248 1.71095C26.7571 2.15939 27 2.76438 27 3.39502V30.605C27 31.2356 26.7571 31.8406 26.3248 32.2891C25.8925 32.7375 25.3055 32.9929 24.6907 33Z"
      fill="#475467"
    />
    <path
      d="M26.344 27.6841H19.3641C19.1902 27.6841 19.0234 27.611 18.9004 27.4809C18.7773 27.3508 18.7082 27.1744 18.7082 26.9904C18.7082 26.8065 18.7773 26.6301 18.9004 26.5C19.0234 26.3699 19.1902 26.2968 19.3641 26.2968H25.6881V5.41512H10.3119V25.5594C10.3119 25.7434 10.2428 25.9198 10.1198 26.0499C9.99674 26.1799 9.82994 26.2531 9.65597 26.2531C9.48199 26.2531 9.3152 26.1799 9.19218 26.0499C9.06916 25.9198 9 25.7434 9 25.5594V4.69367C9 4.5097 9.06916 4.33331 9.19218 4.20322C9.3152 4.07314 9.48199 4 9.65597 4H26.344C26.518 4 26.6848 4.07314 26.8078 4.20322C26.9308 4.33331 27 4.5097 27 4.69367V27.0182C26.9932 27.1973 26.9211 27.3666 26.7989 27.4907C26.6766 27.6149 26.5136 27.6843 26.344 27.6841Z"
      fill="#475467"
    />
    <path
      d="M19.3939 30H16.6061C16.4453 30 16.2912 29.9473 16.1776 29.8535C16.0639 29.7597 16 29.6326 16 29.5C16 29.3674 16.0639 29.2403 16.1776 29.1465C16.2912 29.0527 16.4453 29 16.6061 29H19.3939C19.5547 29 19.7088 29.0527 19.8224 29.1465C19.9361 29.2403 20 29.3674 20 29.5C20 29.6326 19.9361 29.7597 19.8224 29.8535C19.7088 29.9473 19.5547 30 19.3939 30Z"
      fill="#475467"
    />
  </svg>
);

export default CareersCompanyEquipment;
